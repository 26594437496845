/* ProgressBar.css */

.w3-light-grey {
  background-color: #f1f1f1;
  position: relative;
  width: 100%;
}

/* .w3-container {
  padding: 1vh 5px;
} */

.w3-green {
  background-color: #4caf50;
  transition: width 0.2s;
}

.w3-green:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.w3-container > h2,
.w3-container > p {
  margin: 0;
}
