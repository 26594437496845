.chat-threadTablet > *:nth-child(n).message-replyTablet + .messageTablet, .chat-threadTablet > * > .messageTablet:nth-child(n).message-replyTablet + .messageTablet {
    animation: fadeIn 1s ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0s;
}
.chat-threadTablet > *:nth-child(n):not(.message-replyTablet), .chat-threadTablet > * > .messageTablet:nth-child(n):not(.message-replyTablet) {
    opacity: 0;
    animation: fadeIn 1s ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}
.chat-threadTablet > *:nth-child(n) p, .chat-threadTablet > * > .messageTablet:nth-child(n) p {
    margin: 0;
    animation: 1.01s appear 0s forwards;
}
.chat-threadTablet > *:nth-child(n) .typing-indicatorTablet, .chat-threadTablet > * > .messageTablet:nth-child(n) .typing-indicatorTablet {
    will-change: transform;
    display: flex;
    animation: 0s disappear 1s forwards;
}
.chat-threadTablet > *:nth-child(n) .typing-indicatorTablet span, .chat-threadTablet > * > .messageTablet:nth-child(n) .typing-indicatorTablet span {
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 1px;
    background-color: #9e9ea1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}
.chat-threadTablet > *:nth-child(n) .typing-indicatorTablet span:nth-of-type(n), .chat-threadTablet > * > .messageTablet:nth-child(n) .typing-indicatorTablet span:nth-of-type(n) {
    animation: 1s blink infinite 0.3333s;
}
.chat-threadTablet > *:nth-child(n) .typing-indicatorTablet span:nth-of-type(n), .chat-threadTablet > * > .messageTablet:nth-child(n) .typing-indicatorTablet span:nth-of-type(n) {
    animation: 1s blink infinite 0.6666s;
}
.chat-threadTablet > *:nth-child(n) .typing-indicatorTablet span:nth-of-type(n), .chat-threadTablet > * > .messageTablet:nth-child(n) .typing-indicatorTablet span:nth-of-type(n) {
    animation: 1s blink infinite 0.9999s;
}
.chat-threadTablet > *:nth-child(n).message-replyTablet + .messageTablet, .chat-threadTablet > * > .messageTablet:nth-child(n).message-replyTablet + .messageTablet {
    animation: fadeIn 1s ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0s;
}


.messageTablet {
    margin-top: 45px;
    display: flex;
    width: 90%;
    margin-bottom: 40px;
}
.messageTablet::after {
    content: "";
    position: absolute;
    left: 75px;
    border-left: 25px solid transparent;
    border-right: 10px solid transparent;
    border-top: 25px solid #ecf0f1;
}

.messageTablet + .messageTablet {
    margin-top: 0;
}

.messageTablet .avatarTablet {
    width: 75px;
    height: 75px;
    background: url('http://lorempixel.com/75/75');
    margin-right: 20px;
    border-radius: 50%;
}

.messageTablet .message-contentTablet {
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 0.75em 1.5em;
    background: #ecf0f1;
    border-radius: 20px;
}

.messageTablet.message-replyTablet {
    justify-content: flex-end;
}

.messageTablet.message-replyTablet .avatarTablet {
    margin: 0 0 0 20px;
}

.messageTablet.message-replyTablet .message-contentTablet {
    float: right;
    background-color: #0076ff;
    color: #fff;
}

.message-contentTablet p {
    padding: 7px;
}

.messageTablet.message-replyTablet .message-contentTablet p:before {
    content: "";
    position: absolute;
    left: auto;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #0076ff;
}

.chat-actionsTablet {
    text-align: center;
    align-items: center;
}

.chat-actionsTablet button {
    margin-top: 1rem;
}

@keyframes appear {
    0% {
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
   }
    99% {
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
   }
    100% {
        opacity: 1;
        width: auto;
        height: auto;
   }
}
@keyframes blink {
    50% {
        opacity: 1;
   }
}
@keyframes disappear {
    0% {
        opacity: 1;
   }
    99% {
        opacity: 1;
   }
    100% {
        opacity: 0;
        width: 0;
        height: 0;
        overflow: hidden;
   }
}
@keyframes fadeIn {
    from {
        transform: translateY(10px);
        opacity: 0;
   }
    to {
        transform: translateY(0);
        opacity: 1;
   }
}
.containerTablet {
    display: flex;
    height: calc(100vh);
    align-items: center;
    justify-content: center;
}

.profileImageTablet {
    width: 50px;
    vertical-align: middle;
    border-style: none;
    border-radius: 100%;
}

.startButtonTablet{
    left: 50%;
    right: 50%;
    position: relative;
}




  
.mine {
    align-items: flex-end;
  }

  
  .mine .replyMessages {
    display: flex;
    padding: 15px;
    font-size: 15px;
    margin-bottom: 30px;
    margin-right: 25px;
    border-radius: 20px;
    color: white;
    margin-left: 15%;
    background: -webkit-linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background: linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-evenly;
    align-items: center;
}