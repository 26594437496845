
.mineMobile .replyMessagesAnsweresMobile {
  word-break: break-all;
  display: flex;
  padding: 15px;
  font-size: 15px;
  margin-bottom: 30px;
  margin-right: 25px;
  border-radius: 20px;
  color: white;
  margin-left: 35%;
  background: -webkit-linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
  background: linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  position: relative;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;

}

.mineMobile .replyMessagesAnsweresMobile.last:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: -webkit-linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background: linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  
  .mineMobile .replyMessagesAnsweresMobile.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: aliceblue;
    border-bottom-left-radius: 10px;
  }

  

.mineMobile .replyMessagesEmojiesMobile {
    
    display: flex;
    padding: 22px;
    font-size: 36px;
    margin-bottom: 30px;
    margin-right: 25px;
    border-radius: 20px;
    color: white;
    margin-left: 10vh;
    background: -webkit-linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background: linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    position: relative;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-evenly;
    align-items: center;


}




  
  
  .mineMobile .replyMessagesEmojiesMobile.last:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: -webkit-linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background: linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  
  .mineMobile .replyMessagesEmojiesMobile.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: aliceblue;
    border-bottom-left-radius: 10px;
  }
  

  .mineMobile .replyMessagesMobile.last:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: -webkit-linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background: linear-gradient(180deg, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  
  .mineMobile .replyMessagesMobile.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
  }


  .btnEmojiMobile {
      cursor: pointer;
      border-color: #ccc;
      border-radius: 0;
      letter-spacing: 1px;
      transition: all 1s;
      font-size: 80%;
  
}

.btnMobile  {
  
    padding: 10px;
    cursor: pointer;
    border-style: dotted;
    border-color: #002F6F;
    border-radius: 18px;
    letter-spacing: 0px;
    transition: all 1s;
    font-size: 17px;
    margin-right: 1px;
    margin-bottom: 15px;
    border-width: 2px;

}


.startSurveyContainerMobile{
  display: flex;
  margin-right: 10%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.startBtnMobile{
  border: 2px solid #444791;
  padding: 5px 10px;
  border-radius: 5px;
  color: #444791;
  background: #FFFFFF;
  margin-top: 5%;
  margin-right: 10px;
  cursor: pointer;
}