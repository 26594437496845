

.fullbodyTablet{
    width: 100%;
    margin-right: auto;
    /* padding: 1px 150px 10px 900px; */
    /* background-color: white; */
    margin-left: auto;
}


.headerTablet{
  height: 7vh;
  display: flex;
  padding-bottom: 8px;
  background-color: white;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0), 0 1px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: space-between; /* or space-around */
}

.companylogoTablet{
  margin-top: 0px;
}

.chatboxmesagesTablet{
  height: 700px;
  overflow-y: auto;
}

.chatboxmesagesTablet {
  scrollbar-color: #d4aa70 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
}

.chatboxmesagesTablet:hover {
  scrollbar-color: #5749d2;
}

.chatboxmesagesTablet::-webkit-scrollbar {
  width: thin;
}
.smallimgzTablet {
  display: flex;
  margin-top: 30px;

}

.smallimgz1Tablet{
  /*padding-right: 10px;*/
  padding-left: 20px;
  margin-top: -10px;
}

.smallimgz1Tablet img{
     width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 3px solid #002F6C;
  margin-top: 20px;
  padding: 5px;
  box-shadow: inset 0 0 4px #002F6C;
}


.chatboxTablet {
  width: 80%;
  margin: 10px;
  background: #002F6C;
  padding: 10px;
  text-align: left;
  color: #fff;
  position: relative;
  font-size: 15px;
  border-radius: 0 20px 20px 20px;
  border: 0px solid;
  padding: 10px;
  box-shadow: 5px 10px 18px #888888;

  }


 
  .infoButton{
    height: 100%;
    background: white;
    border-width: 0px;
  }




.buttonsettingTablet {
  background-color: white;
  border:white;
  }

.wematterlogoTablet{
  height: 50%;
  margin: 15px;
}

.settingIconTablet{
    margin-top: 0px;
    margin-right: 13px;
    width: 37px;
    height: 50px;
    animation: spin 4s linear infinite;

  }
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
  }
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
  }

  