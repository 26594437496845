.card {
  /* box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0), 0 1px 20px 0 rgba(0, 0, 0, 0.19); */
  height: calc(100dvh - 90px - 70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-bottom: 12%;
}
.container .card {
  width: calc(50vw);
}

.chat-thread {
  /* margin-top: 10px; */
  /* height: 77vh; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-thread::-webkit-scrollbar {
  width: 5px;
}

.chat-thread::-webkit-scrollbar-track {
  background: #a2abe5;
  border-radius: 10px;
}

.chat-thread::-webkit-scrollbar-thumb {
  background: #5d5fef;
  border-radius: 10px;
}

.textareacss {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 10px;
  width: 95%;
}
textareacss:focus {
  background-color: azure;
}

.commentbox {
  word-break: break-all;
  resize: vertical;
  width: 100%;
  border-radius: 15px;
  border-radius: 16px;
  border: 1px solid #a2abe5;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(31, 19, 0, 0.12);
  outline: none;
}

.sendIcon {
  /* width: 50px; */
  height: 50%;
  position: absolute;
  right: 3%;
  cursor: pointer;
}

@media screen and (max-width: 520px) {
  .card {
    height: calc(100dvh - 4rem - 0.5rem - 70px);
    box-sizing: border-box;
  }
}
