.containerMobile .cardMobile {
  width: calc(50vw);
}

.chat-threadMobile {
  margin-top: 10px;
  height: 69vh;
  overflow-y: scroll;
}

.chat-threadMobile::-webkit-scrollbar {
  width: 5px;
}

.chat-threadMobile::-webkit-scrollbar-track {
  background: #a2abe5;
  border-radius: 10px;
}

.chat-threadMobile::-webkit-scrollbar-thumb {
  background: #5d5fef;
  border-radius: 10px;
  background: #666;
}

.chat-threadMobile::-webkit-scrollbar-thumb {
  background: #ddd;
}

.textareacssMobile {
  padding-top: 13px;
  padding-left: 30px;
  /* padding-left: 30px; */
  display: flex;
}

textareacss:focus {
  background-color: azure;
}

.commentboxMobile {
  padding-left: 10px;
  /* padding-top: 10px; */
  margin-left: 10px;
  width: 68%;
  border-radius: 15px;
}

.sendIconMobile {
  margin-left: 20px;
  width: 50px;
  height: 50px;
}
