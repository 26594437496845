
.questionAnswerMobile{
    margin: 10px;
}


.editIconMobile{
    
        width: 23px;
        height: 24px;
        left: 92%;
        position: relative;
        bottom: 35px;
  }