.questionAnswer {
  margin: 10px;
}

.editIcon {
  bottom: 25px;
  width: 21px;
  height: 21px;
  left: 97%;
  position: relative;
  /* top: -2px; */
  cursor: pointer;
}

@media screen and (max-width: 520px) {
  .editIcon {
    left: 95%;
  }
}
