.containerTablet .cardTablet {
  width: calc(50vw);
}

.chat-threadTablet {
  margin-top: 10px;
  height: 75vh;
  overflow-y: scroll;
}

.chat-threadTablet::-webkit-scrollbar {
  width: 5px;
}

.chat-threadTablet::-webkit-scrollbar-track {
  background: #666;
}

.chat-threadTablet::-webkit-scrollbar-thumb {
  background: #ddd;
}

.textareacssTablet {
  padding-top: 16px;
  padding-left: 30px;
}

textareacss:focus {
  background-color: azure;
}

.commentboxTablet {
  padding-left: 10px;
  padding-top: 10px;
  margin-left: 10px;
  width: 83%;
  border-radius: 15px;
}

.sendIconTablet {
  margin-left: 20px;
  width: 50px;
  height: 50px;
}
