.chat-thread > *:nth-child(n).message-reply + .message,
.chat-thread > * > .message:nth-child(n).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}
.chat-thread > *:nth-child(n):not(.message-reply),
.chat-thread > * > .message:nth-child(n):not(.message-reply) {
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.chat-thread > *:nth-child(n) p,
.chat-thread > * > .message:nth-child(n) p {
  margin: 0;
  animation: 1.01s appear 0s forwards;
}
.chat-thread > *:nth-child(n) .typing-indicator,
.chat-thread > * > .message:nth-child(n) .typing-indicator {
  will-change: transform;
  display: flex;
  animation: 0s disappear 1s forwards;
}
.chat-thread > *:nth-child(n) .typing-indicator span,
.chat-thread > * > .message:nth-child(n) .typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.chat-thread > *:nth-child(n) .typing-indicator span:nth-of-type(n),
.chat-thread > * > .message:nth-child(n) .typing-indicator span:nth-of-type(n) {
  animation: 1s blink infinite 0.3333s;
}
.chat-thread > *:nth-child(n) .typing-indicator span:nth-of-type(n),
.chat-thread > * > .message:nth-child(n) .typing-indicator span:nth-of-type(n) {
  animation: 1s blink infinite 0.6666s;
}
.chat-thread > *:nth-child(n) .typing-indicator span:nth-of-type(n),
.chat-thread > * > .message:nth-child(n) .typing-indicator span:nth-of-type(n) {
  animation: 1s blink infinite 0.9999s;
}
.chat-thread > *:nth-child(n).message-reply + .message,
.chat-thread > * > .message:nth-child(n).message-reply + .message {
  animation: fadeIn 1s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s;
}

.message {
  /* margin-top: 45px; */
  display: flex;
  /* width: 90%; */
  margin-bottom: 20px;
}
/* .message::after {
  content: "";
  position: absolute;
  left: 75px;
  border-left: 25px solid transparent;
  border-right: 10px solid transparent;
  border-top: 25px solid #ecf0f1;
} */

.message + .message {
  margin-top: 0;
}

.message .avatar {
  width: 52px;
  height: 52px;
  background: url("http://lorempixel.com/75/75");
  margin-right: 20px;
  align-self: end;
}

.message .message-content {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px 16px 16px 0px;
  background: #f4f4f4;
  box-shadow: 0px 4px 8px 0px rgba(113, 68, 0, 0.04);
  margin-right: 10px;
  max-width: 80%;
}

.message.message-reply {
  justify-content: flex-end;
}

.message.message-reply .avatar {
  margin: 0 0 0 20px;
}

.message.message-reply .message-content {
  float: right;
  background-color: #0076ff;
  color: #fff;
}

.message.message-reply .message-content p:before {
  content: "";
  position: absolute;
  left: auto;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #0076ff;
}

.chat-actions {
  text-align: center;
  align-items: center;
}

.chat-actions button {
  margin-top: 1rem;
}

@keyframes appear {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  99% {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    width: auto;
    height: auto;
  }
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@keyframes fadeIn {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.container {
  display: flex;
  height: calc(100vh);
  align-items: center;
  justify-content: center;
}

.profileImage {
  width: inherit;
  vertical-align: middle;
  border-style: none;
  border-radius: 50%;
  border: 1px solid #5d5fef;
}

.startButton {
  left: 50%;
  right: 50%;
  position: relative;
}

.mine {
  align-items: flex-end;
  width: 100%;
  display: flex;
  justify-content: end;
}

.mine .replyMessages {
  display: flex;
  padding: 15px;
  font-size: 16px;
  margin-bottom: 30px;
  margin-right: 25px;
  border-radius: 20px;
  color: white;
  margin-left: 15%;
  background: -webkit-linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background: linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 520px) {
  .message .avatar {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}
