.mine .replyMessagesAnsweres {
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 16px;
  background: #5d5fef;
  box-shadow: 0px 4px 8px 0px rgba(31, 19, 0, 0.25);
  color: white;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  width: fit-content;
}

/* .mine .replyMessagesAnsweres.last:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: -webkit-linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background: linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .replyMessagesAnsweres.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
} */

.mine .replyMessagesEmojies {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 16px;
  /* background: #5d5fef; */
  /* box-shadow: 0px 4px 8px 0px rgba(31, 19, 0, 0.25); */
  color: white;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  width: fit-content;
}

/* .mine .replyMessagesEmojies.last:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: -webkit-linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background: linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .replyMessagesEmojies.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
} */

.mine .replyMessages.last:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: -webkit-linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background: linear-gradient(180deg, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .replyMessages.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.btnEmoji {
  cursor: pointer;
  border-color: #ccc;
  border-radius: 0;
  letter-spacing: 1px;
  transition: all 1s;
  font-size: 25px;
}

.btn {
  padding: 10px;
  cursor: pointer;
  border-style: dotted;
  border-color: #002f6f;
  border-radius: 18px;
  letter-spacing: 0px;
  transition: all 1s;
  font-size: 12px;
  margin-right: 10px;
  border-width: 2px;
}

.startSurveyContainer {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 520px) {
  .startSurveyContainer {
    flex-direction: column;
  }
}

.startBtn {
  border-radius: 12px;
  border: 2px solid #dfdfff;
  color: #5d5fef;
  text-align: right;
  font-weight: 500;
  /* margin-top: 10px;*/
  /* margin-left: 5%; */
  cursor: pointer;
}

@media screen and (max-width: 520px) {
  .startBtn {
    text-align: center;
    width: 100%;
  }
}

.emoji-options {
  transition: transform 250ms;
}

.emoji-options:hover {
  transform: translateY(-10px);
}
