:root {
  --vh: 100vh;
}

.main-container {
  position: relative;
  height: 100dvh;
}
.fullbody {
  width: 902px;
  margin-right: auto;
  background-color: #ffffff;
  margin-left: auto;
}

.header {
  display: flex;
  padding-bottom: 8px;
  background-color: white;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0), -3px -2px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: space-between; /* or space-around */
}

.companylogo {
  height: 5vh;
  width: 5vh;
  margin-top: -20px;
}

.chatboxmesages {
  height: 700px;
  overflow-y: auto;
}

.chatboxmesages {
  scrollbar-color: #d4aa70 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
}

.chatboxmesages:hover {
  scrollbar-color: #5749d2;
}

.chatboxmesages::-webkit-scrollbar {
  width: thin;
}
.smallimgz {
  display: flex;
  margin-top: 30px;
}

.smallimgz1 {
  /*padding-right: 10px;*/
  padding-left: 20px;
  margin-top: -10px;
}

.smallimgz1 img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 3px solid #002f6c;
  margin-top: 20px;
  padding: 5px;
  box-shadow: inset 0 0 4px #002f6c;
}

.chatbox {
  width: 80%;
  margin: 10px;
  background: #002f6c;
  padding: 10px;
  text-align: left;
  color: #fff;
  position: relative;
  font-size: 20px;
  border-radius: 0 20px 20px 20px;
  border: 0px solid;
  padding: 10px;
  box-shadow: 5px 10px 18px #888888;
}

.infoButton {
  height: 100%;
  background: white;
  border-width: 0px;
}

.buttonsetting {
  background-color: white;
  border: white;
}

.wematterlogo {
  height: 5vh;
  width: 10vh;
  /* margin: 15px; */
  margin-top: 21px;
  margin-left: 10px;
}

.infoIcon {
  margin-top: 4px;
  margin-right: 3px;
  height: 4vh;
  width: 4vh;
  margin-bottom: 2px;
}

.settingIcon {
  margin-top: 10px;
  margin-right: 10px;
  height: 4vh;
  width: 4vh;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.content {
  padding-bottom: env(safe-area-inset-bottom);
}

.logo-imgs {
  width: 150px;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .ReactModal__Content {
    width: 60% !important;
  }
}

@media screen and (max-width: 520px) {
  .ReactModal__Content {
    width: 90% !important;
    padding: 33px 30px !important;
  }

  .logo-imgs {
    width: 100px;
  }
}
