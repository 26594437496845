/* ProgressBar.css */

.w3-light-greyTablet {
  background-color: #f1f1f1;
  position: relative;
  width: 100%;
}

.w3-containerTablet {
  padding: 16px;
}

.w3-greenTablet {
  background-color: #4caf50;
  height: 100%;
  transition: width 0.2s;
}

.w3-greenTablet:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.w3-containerTablet > h2,
.w3-containerTablet > p {
  margin: 0;
}
